import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageTitle from '../../components/page_title'
import PageContents from '../../components/page_contents'
import * as style from '../../styles/profile.module.css'

export default function ProfileMary() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Mary Linden'
        description='Click here to learn more about Mary Linden of the Linden Law Group.'
      />

      <PageContents>
        <PageTitle>Who We Are</PageTitle>

        <div className={`${style.main} wide_content`}>
          <StaticImage
            className={style.photo}
            src='../../images/jpg/mary-smile.jpg'
            alt='Mary Kominek Linden'
            loading='eager'
            height={400}
          />

          <div className={style.description}>
            <span className={style.name}>Mary Kominek Linden</span>
            <span className={style.role}>Attorney, Partner</span>

            <div className={style.text}>
              Mary is a native of Colorado who, in 1994, accepted her first
              position as a lawyer here in Colorado Springs. Her practice
              consists of state and federal court litigation at both the trial
              and appellate levels. Mary's strengths as a seasoned legal analyst
              and writer have allowed Linden Law Group to undertake even the
              most complex litigation without hesitation.
              <br />
              <br />
              Mary has been elected President of the El Paso County Bar
              Association (2021-22), and President of the El Paso County Women's
              Bar Association (2015-16), and was appointed by Gov. Hickenlooper
              to the Judicial Nominating Commission for the Fourth Judicial
              District (2016-21). She has been selected to membership in the
              Colorado Bar Association Leadership Training Program (2018), the
              Ben S. Wendelken Inn of Court, and the Colorado Bar Foundation,
              and serves on many local boards. Mary received her Bachelor of
              Science in Business Administration from the University of
              Colorado, Boulder (1989), and her Juris Doctor from the University
              of Texas, Austin (1993). She has been admitted in the state courts
              of Colorado (1994), California (1993), and Texas (1995), and the
              U.S. District Court, Colorado (1994) and U.S. Court of Appeals,
              Tenth Circuit (2004).
              <br />
              <br />
              Mary holds an AV Preeminent Peer Review Rating and is very proud
              of her relentless pursuit of justice, passion for the law, and
              dedication to advancing her clients' interests. Most importantly,
              Mary's three amazing children keep her focused on the challenges
              and opportunities we are creating for future generations.
            </div>

            <Link to='/about/vincent' className={style.link}>
              Vincent L. Linden III
            </Link>
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
